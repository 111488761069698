<template>
  <div class="main-content">
    <h3 class="marginBottom20">修改密码</h3>
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      @submit="handleSubmit"
    >
      <template slot="tip"
        ><div style="color: #aaaaaa">
          *忘记密码请联系管理员重置后修改
        </div></template
      >
      <template slot="tipPassWord"
        ><div style="color: #aaaaaa">
          *密码需包含大写字母、小写字母、数字
        </div></template
      >
    </avue-form>
  </div>
</template>

<script>
import { logout, userpassword } from "@/api/index";
import { enterNumberEnglish } from "../../utils/formRules/index";
export default {
  name: "index",
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPassword1: "",
      },
      rolechangeType: localStorage.getItem("roleChangeType"), //控制切换 退出登录
      option: {
        labelWidth: 120,
        submitText: "确认修改",
        emptyBtn: false,
        column: [
          {
            label: "原始密码",
            span: 12,
            maxlength: 30,
            showWordLimit: true,
            type: "password",
            prop: "oldPassword",
            rules: [
              {
                required: true,
                message: "原始密码",
                trigger: "blur",
              },
              ...enterNumberEnglish,
            ],
          },
          {
            label: "",
            span: 12,
            prop: "tip",
            labelWidth: 0,
          },
          {
            label: "新密码",
            span: 12,
            // row: true,
            maxlength: 30,
            showWordLimit: true,
            type: "password",
            prop: "newPassword",
            rules: [
              {
                required: true,
                message: "请输入新密码",
                trigger: "blur",
              },
              ...enterNumberEnglish,
            ],
          },
          {
            label: "",
            span: 12,
            prop: "tipPassWord",
            labelWidth: 0,
          },
          {
            label: "新密码确认",
            span: 12,
            row: true,
            maxlength: 30,
            showWordLimit: true,
            type: "password",
            prop: "newPassword1",
            rules: [
              {
                required: true,
                message: "新密码确认",
                trigger: "blur",
              },
              ...enterNumberEnglish,
            ],
          },
        ],
      },
    };
  },
  methods: {
    handleSubmit(form, done) {
      let that = this;
      if (!form.oldPassword || form.oldPassword === "") {
        this.$message.error("原密码不能为空");
        return done();
      }
      if (!form.newPassword || form.newPassword === "") {
        this.$message.error("新密码不能为空");
        return done();
      }
      if (!form.newPassword1 || form.newPassword1 === "") {
        this.$message.error("确认密码不能为空");
        return done();
      }
      if (form.newPassword !== form.newPassword1) {
        this.$message.error("确认密码错误");
        return done();
      }
      userpassword(form)
        .then((res) => {
          // 获取数据成功后的其他操作
          if (res.code === 200) {
            that.$message.success(res.msg);
            setTimeout(() => {
              logout()
                .then((res) => {
                  if (res.code === 200) {
                    this.rolechangeType == 1
                      ? this.$router.push("/login")
                      : this.rolechangeType == 2
                      ? this.$router.push("/platformLogin")
                      : this.$router.push("/supplierLogin");
                    this.global.Set_store();
                    this.global.SET_localStorage();
                  }
                })
                .catch((err) => {
                  this.$message.error(err.msg);
                });
            }, 1000);
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch((err) => {
          done();
        });
    },
  },
};
</script>

<style scoped>
</style>